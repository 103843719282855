import Logo from "../../assets/images/header/SIA Logo.png";
import Styles from "./Navbar.module.css";
import Image from "next/image";
import Link from "next/link";
import chennai from "../../assets/images/header/chn.png";
import delhi from "../../assets/images/header/del.png";
import bengaluru from "../../assets/images/header/ben.png";
import thiruvandram from "../../assets/images/header/tvn.png";
import hyderabad from "../../assets/images/header/hyd.png";
// import UPSC from "../../assets/images/header/UPSC.png";
// import TNPSC from "../../assets/images/header/TNPSC.png";
// import Banking from "../../assets/images/header/banking.png";
import Modal from 'react-bootstrap/Modal';
import Close from "../../assets/images/home/close.webp";
import { useState, useEffect } from "react";


const Nav = () => {
  const [modalShow, setModalShow] = useState(false);
  // const [modalShow1, setModalShow1] = useState(false);
  // const [modalShow2, setModalShow2] = useState(false);

  const announcement = [
    { link: "/upsc-admission-anna-nagar", cont: "UPSC - Prelims cum Mains (Foundation Course)" },
    { link: "/two-year-upsc-program",     cont: "Sadhana II Year (UPSC Foundation Course)" },
    { link: "/upsc-interview",            cont: "UPSC Civilisation interview 2024" },
    { link: "/upsc-prelims-test-series",  cont: "UPSC Prelims Test Series 2025" },
   

    
  ]

  const announcements = [
    { link: "/upsc-optional-test-series", cont: "UPSC Optional Test Series 2024" },
    { link: "/upsc-mains-test-series",    cont: "UPSC GS-Mains Test Series 2024 (Moksha)" },
  
    { link: "/upsc-environment-book",     cont: "UPSC Environment Book Buy Now" },
    
    ]

  const announcement1 = [
    {link: "/tnpsc-admissions",  cont: " Gr I, II & IIA Prelims Coaching "},
    {link: "https://www.shankariasacademy.com/blog/tnpsc-group-iia-mains-mock-test/",  cont: " TNPSC Group IIA Mains Mock Test Registration 2025 "},
    {link: "https://www.shankariasacademy.com/blog/tnpsc-group-ii-mains-mock-test/",  cont: " TNPSC Group II Mains Mock Test Registration 2025 "},
    {link: "/tnpsc-group-iv-test-batch", cont: "TNPSC Group IV Prelims Test Series 2024" },
    {link: "/tnpsc-group-i-prelims-test-series",  cont: " TNPSC Group I Prelims Test Series 2024 "},
  ]
  const announcements1 = [
  

  ]

  const announcement2 = [
    { link: "https://www.shankarbankingacademy.com/ssc-banking-admission/", route: "_blank", cont: "Shankar Banking Academy - Banking Current Affairs | SSC Current affairs | Railway and Insurance Current affairs" }
  ]
  const announcements2 = [
    { link: "https://www.shankarbankingacademy.com/ssc-banking-admission/", route: "_blank", cont: "SSC CGL CRASH COURSE 2024" }
  ]

  return (
    <>
      <div className="container-fluid p-0 d-none d-lg-block">
        <nav className={`${Styles.navstyle} navbar navbar-expand-lg`}>
          <div className="container mb-3 pt-2">
            <Link href="/" className="pe-lg-2 pe-xl-3 me-xl-4">
              <Image src={Logo} className={`${Styles.logoimg} img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
            </Link>

            <div className="position-relative pt-2">
              <div onClick={() => setModalShow(true)} className={`text-center cursorPointer ${Styles.announcebtn}`}>What's New</div>

              {/* <Link onClick={() => setModalShow(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-lg-2 pe-xl-4`} href="">UPSC</Link>
              <Link onClick={() => setModalShow1(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-lg-2 pe-xl-4`} href="">TNPSC</Link>
              <Link onClick={() => setModalShow2(true)} className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1} pe-lg-2 pe-xl-4`} href="">Banking</Link> */}
            </div>

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >

              <ul className="navbar-nav text-center">
                <li className="nav-item custom-border">
                  <Link href="/upsc-admission-anna-nagar">
                    <Image src={chennai} className={`${Styles.logoimg}  img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                    <button type="button" className="newnav" >Chennai</button></Link>
                </li>
                <li className="nav-item custom-border">
                  <Link href="/delhi">
                    <Image src={delhi} className={`${Styles.logoimg}`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                    <button type="button" className= {`${Styles.delhibtn} newnav text-white`} >Delhi</button></Link>
                </li>
                <li className="nav-item custom-border">
                  <Link href="/bengaluru">
                    <Image src={bengaluru} className={`${Styles.logoimg} img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                    <button type="button" className="newnav" >Bengaluru</button></Link>
                </li>
                <li className="nav-item custom-border pe-xl-2 mx-xl-2">
                  <Link href="/thiruvananthapuram">
                    <Image src={thiruvandram} className={`${Styles.logoimg} img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                    <button type="button" className="newnav" >Thiruvananthapuram</button></Link>
                </li>
                <li className="nav-item">
                  <Link href="/hyderabad">
                    <Image src={hyderabad} className={`${Styles.logoimg} img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                    <button type="button" className="newnav">Hyderabad</button></Link>
                </li>
              </ul>

            </div>

          </div>
        </nav>
      </div>

      <div>
        <Modal
          size="lg"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Body className="p-md-5 p-lg-4 p-xl-5">
            <div className="text-end">
              <Image onClick={() => setModalShow(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
            </div>
            <p className={`${Styles.announcecont1}`}>What's New</p>
            {/* <div className="row">
              <div className="col pb-3">
                <Image src={UPSC} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>UPSC</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6  col-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr style={{opacity:"1"}}></hr>
           
            {/* <div className="row">
              <div className="col pb-3">
                <Image src={TNPSC} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>TNPSC</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement1.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements1.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          <hr style={{opacity:"1"}}></hr>
          {/* <p className={`${Styles.announcecont1}`}>What's New</p>
            <div className="row">
              <div className="col pb-3">
                <Image src={Banking} className="img-fluid" alt="new_notifications" />
              </div>
            </div> */}
            <div className="row">
            <p className={`${Styles.toptopheaderlinks2} ${Styles.toptopheader1}`}>Banking</p>
              <div className="col-lg-6 col-md-12">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement2.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcements2.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </div>

      {/* <div>
        <Modal
          size="lg"
          centered
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        >
          <Modal.Body className="p-md-5">
            <div className="text-end">
              <Image onClick={() => setModalShow1(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
            </div>
            <p className={`${Styles.announcecont1}`}>What's New</p>
            <div className="row">
              <div className="col pb-3">
                <Image src={TNPSC} className="img-fluid" alt="new_notifications" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement1.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" id={item.contcolor} target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div> */}


      {/* <div>
        <Modal
          size="lg"
          centered
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        >
          <Modal.Body className="p-md-5">
            <div className="text-end">
              <Image onClick={() => setModalShow2(false)} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer" }} />
            </div>
            <p className={`${Styles.announcecont1}`}>What's New</p>
            <div className="row">
              <div className="col pb-3">
                <Image src={Banking} className="img-fluid" alt="new_notifications" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul className={`${Styles.ulclass1} ps-1 pt-2 mb-0`}>
                  {announcement2.map((item, index) => (
                    <li className={`${Styles.ulliclass1}`} key={index}>
                      <div className="d-inline">
                        <p className={`${Styles.ullispan1} d-inline`}>
                          <Link href={item.link} rel="noreferrer" target={item.route} className={`${Styles.announcecont2}`}>{item.cont}</Link>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div> */}
     

    </>
  );
};

export default Nav;
