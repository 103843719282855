import headerstyle from "./Navbar.module.css";
import Image from "next/image";
import homemail from "../../assets/images/header/mail.png";
import homephone from "../../assets/images/header/phone.png";
import { useState } from "react";
import Nav from "./Nav";
import Link from "next/link";
import Head from "next/head";

const NavbarDesk = (props) => {

  const [active, setActive] = useState(props.title);

  return (
    <>
 
  <div className="">
  
    <div className="container-fluid d-lg-block d-none" id={`${headerstyle.navbartopbg}`}>
        <div className="container d-lg-none d-xl-block">
            <div className="row">
                <div className={`${headerstyle.topheaderscroll} col`}>
                    <span id={`${headerstyle.toptopleftsec}`}>
                        <Image 
                          src={homemail} 
                          alt="mail icon" 
                          title="Mail Link"
                          className="img-fluid"
                         />
                         <span className={`${headerstyle.toptopheader} ps-xl-1 pe-xl-2`}>
                          <a href="mailto:admissions@shankarias.in" className={`${headerstyle.toptopheaderlinks}`}>admissions@shankarias.in</a>
                          </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Image 
                          src={homephone} 
                          alt="phone icon" 
                          title="Phone Link"
                          className="img-fluid"   
                        />
                        <span className={`${headerstyle.toptopheader} ps-1 pe-2`}>UPSC:
                          <a href="tel: 9003190030" className={`${headerstyle.toptopheaderlinks}`}> &nbsp; 9003190030 &nbsp;&nbsp;</a>
                        </span>
                        <span className={`${headerstyle.topdot}`}><b id={`${headerstyle.topleft}`}>.</b></span>
                        <span className={`${headerstyle.toptopheader} ps-1 pe-2`}>TNPSC:
                          <a href="tel: 044-43533445" className={`${headerstyle.toptopheaderlinks}`}>&nbsp;&nbsp;7667766266 &nbsp;|&nbsp; 044-45543082 &nbsp;|&nbsp;044-43533445 </a>
                        </span>
                    </span>
                    
                  <div className={`${headerstyle.headerscroll}`}>
                          <Link href="/upsc-admission-anna-nagar" className={`${headerstyle.toptopheaderlinks1} ${headerstyle.toptopheader1} pe-lg-3 pe-xl-4`} >
                            <span className={active === "upsc" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="upsc" onClick={() => setActive("upsc")}>Domestic</span>
                          </Link>
                          <Link href="/upsc-admission-nri" className={`${headerstyle.toptopheaderlinks1} ${headerstyle.toptopheader1} pe-lg-3 pe-xl-4`} >
                            <span className={active === "upsc" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="upsc" onClick={() => setActive("upsc")}>NRI</span>
                          </Link>
                          <Link href="/contact-us" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`} >
                            <span className={active === "contact" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="contact" onClick={() => setActive("contact")}>Contact</span>
                          </Link>
                          <Link href="/careers" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            <span className={active === "career" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="career" onClick={() => setActive("career")}>Careers</span>
                          </Link>
                          <a href="/blog" rel="noreferrer" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            <span className={active === "blog" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="blog" onClick={() => setActive("blog")}>Blog</span>
                          </a>
                          <a href="https://admissions.shankariasacademy.com/online/" target={"_blank"} rel="noreferrer" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            {/* <span className={active === "login"? `${headerstyle.activeLink} nav-item`: "nav-item"} id="login" onClick={() => setActive("login")}>Login</span> */}
                            Login
                          </a>
                        <Link href="/admission-consultation"><button type="button" className={`${headerstyle.headerbutton}`}>Admission Consultation</button></Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid d-lg-block d-xl-none">
        <div className="row">
                <div className={`${headerstyle.topheaderscroll} col`}>
                    <span id={`${headerstyle.toptopleftsec}`}>
                        <Image 
                          src={homemail} 
                          alt="mail icon" 
                          title="Mail Link"
                          className="img-fluid"
                         />
                         <span className={`${headerstyle.toptopheader}`}>
                          <a href="mailto:admissions@shankarias.in" className={`${headerstyle.toptopheaderlinks}`}>admissions@shankarias.in</a>
                          </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Image 
                          src={homephone} 
                          alt="phone icon" 
                          title="Phone Link"
                          className="img-fluid"   
                        />
                        <span className={`${headerstyle.toptopheader} ps-1 pe-2`}>UPSC:
                          <a href="tel: 044-66024500" className={`${headerstyle.toptopheaderlinks}`}> 7667766266 &nbsp;|&nbsp; 044-66024500</a>
                        </span>
                        <span className={`${headerstyle.topdot}`}><b id={`${headerstyle.topleft}`}>.</b></span>
                        <span className={`${headerstyle.toptopheader} ps-1 pe-2`}>TNPSC:
                          <a href="tel: 044-43533445" className={`${headerstyle.toptopheaderlinks}`}>&nbsp;&nbsp;044-43533445 &nbsp;|&nbsp; 044-45543082</a>
                        </span>
                    </span>
                    
                  <div className={`${headerstyle.headerscroll}`}>
                          <Link href="/upsc-admission-anna-nagar" className={`${headerstyle.toptopheaderlinks1} ${headerstyle.toptopheader1} pe-lg-3 pe-xl-4`} >
                            <span className={active === "upsc" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="upsc" onClick={() => setActive("upsc")}>Domestic</span>
                          </Link>
                          <Link href="/upsc-admission-nri" className={`${headerstyle.toptopheaderlinks1} ${headerstyle.toptopheader1} pe-lg-3 pe-xl-4`} >
                            <span className={active === "upsc" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="upsc" onClick={() => setActive("upsc")}>NRI</span>
                          </Link>
                          <Link href="/contact-us" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`} >
                            <span className={active === "contact" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="contact" onClick={() => setActive("contact")}>Contact</span>
                          </Link>
                          <Link href="/careers" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            <span className={active === "career" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="career" onClick={() => setActive("career")}>Careers</span>
                          </Link>
                          <a href="/blog" rel="noreferrer" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            <span className={active === "blog" ? `${headerstyle.activeLink} nav-item`: "nav-item"} id="blog" onClick={() => setActive("blog")}>Blog</span>
                          </a>
                          <a href="https://admissions.shankariasacademy.com/online/" target={"_blank"} rel="noreferrer" className={`${headerstyle.toptopheaderlinks} ${headerstyle.toptopheader} pe-lg-3 pe-xl-4`}>
                            {/* <span className={active === "login"? `${headerstyle.activeLink} nav-item`: "nav-item"} id="login" onClick={() => setActive("login")}>Login</span> */}
                            Login
                          </a>
                        <Link href="/admission-consultation"><button type="button" className={`${headerstyle.headerbutton}`}>Admission Consultation</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Nav title={props.title}/>
    </div>
        </>
  )
}
export default NavbarDesk;
