import styles from "./Home.module.css";
import Image from "next/image";
import pin from "../../assets/images/header/pin.png";
import googleplay from "../../assets/images/home/googleplay.png";
import apple from "../../assets/images/home/apple.png";
import mobileimg from "../../assets/images/home/mobileimg.webp";
import event from "../../assets/images/home/event.png";
import icon1 from "../../assets/images/home/toppers.webp";
import icon2 from "../../assets/images/home/faculty.webp";
import icon3 from "../../assets/images/home/guidance.webp";
import icon4 from "../../assets/images/home/mentoring.webp";
import icon5 from "../../assets/images/home/test series.webp";
import icon6 from "../../assets/images/home/program.webp";
import preferredcivil from "../../assets/images/home/preferredcivil.webp";
import medal from "../../assets/images/home/medal.webp";
import youtube_thumnail_1 from "../../assets/images/home/youtube/youtube_thumnail_33.webp";
import youtube_thumnail_2 from "../../assets/images/home/youtube/youtube_thumnail_22.webp";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Close from "../../assets/images/home/Icon ionic-ios-close-circle.png";
// import Sadhana from "../../assets/images/home/Pre-book-2024_web-popup.webp";
import Upsc_popup from "../../assets/images/home/GSPCM-Popup.jpg";
import Link from "next/link";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//import dynamic from "next/dynamic";
//const Acheiver = dynamic(() => import("../../components/our-acheiver/achievers"));
import Acheiver from "../../components/our-acheiver/achievers";
import Newicon from "../../assets/images/header/new.svg";
import NewiconA from "../../assets/images/header/newanimation.gif";
import Head from "next/head";
import Slider from "../../components/homeslider/slider"

const Homepage = () => {

  const [modalIsOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  useEffect(() => {
    let session = sessionStorage.getItem('notify')
    if (session == null) {
      openModal();
      sessionStorage.setItem('notify', true);
    }
  }, []);

  const [play, setPlay] = useState(false);
  const [play1, setPlay1] = useState(false);

  
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    "name": "Best IAS Academy in Chennai | Shankar IAS Academy",
    "url": "https://www.shankariasacademy.com/",
    "logo":
      "https://www.shankariasacademy.com/_next/static/media/SIA%20Logo.f7bc4ef0.png",
    "description": "Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results.",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "telephone": "+91-7667766266",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-66024500",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-43533445",
        "contactType": "UPSC, TNPSC Admissions"
      },
      {
        "@type": "ContactPoint",
        "telephone": "044-45543082",
        "contactType": "UPSC, TNPSC Admissions"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/shankariasacademy",
      "https://twitter.com/shankarias",
      "https://www.instagram.com/shankariasacademy/",
      "https://www.youtube.com/c/ShankarIASAcademyVideos"
    ]
  }
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq('track', 'ButtonClick');
    }
    // Your button click logic here
    console.log('Button clicked!');
  };
  return (
    <>
      <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd,null, "\t") .replaceAll("],\n\t\"", "],\n\n\t\"") }} />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="robots" content="noodp,noydir" />
        <meta name="robots" content="index,follow" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta property="article:publisher" content="https://www.facebook.com/shankariasacademy" />
        <meta property="article:modified_time" content="2024-01-25T05:43:44+00:00" />
        <title>Best IAS Academy in Chennai | Shankar IAS Academy</title>
        <meta name="title" content="Best IAS Academy in Chennai | Shankar IAS Academy" />
        <meta name="description" content="Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results." />
        <link rel="canonical" href="https://www.shankariasacademy.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best IAS Academy in Chennai | Shankar IAS Academy" />
        <meta property="og:description" content="Achieve UPSC success with top coaching at the Best IAS Academy in Chennai. Expert mentors, comprehensive curriculum, and proven results." />
        <meta property="og:url" content="https://www.shankariasacademy.com/" />
        <meta property="og:site_name" content="Shankar IAS Academy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@shankarias" />
      </Head>

      {/*<Modal
        size="lg"
        centered
        show={modalIsOpen}
        onHide={closeModal}
        aria-label="UPSC Modal"
        className="blur"
      >
        <Modal.Body className="p-0">
          <div className="position-absolute  p-0 p-md-1 closebtn">
            <Image loading="eager" placeholder="blur" onClick={closeModal} alt="Close icon" title="Close modal" src={Close} className="img-fluid" style={{ cursor: "pointer", width: "20px" }} />
             
          </div>
          <Image src={Upsc_popup} alt="Modal Banner" className="img-fluid w-100"  useMap="#image-map"/>
              <map name="image-map">
                
                {/* Add more areas as needed 
                <area target="_self" alt="UPSC" title="UPSC" href="https://www.shankariasacademy.com/upsc-admission-anna-nagar/" coords="39,41,1604,777" shape="rect"></area>
                <area target="_self" alt="TNPSC" title="TNPSC" href="https://www.shankariasacademy.com/tnpsc-admissions/" coords="55,855,1625,1285" shape="rect"></area>
            </map>
        </Modal.Body>
      </Modal> */}

      <Navbar title="home" />
      <Slider />
       <div className="container  d-none d-md-block">
          <div className={`${styles.centerContent}`}>
            <Link href="https://www.shankariasacademy.com/blog/institute-day-2024-20-years-celebration/">
                <Image src={event} className={`${styles.logoimg} pt-4 img-fluid`} alt="shankar ias academy logo" title="Shankar IAS Academy" />
                <h2 className={`${styles.sectiontitle1} ${styles.fonthover}`}>20th Anniversary Celebration </h2>
                <button type="button" className={`${styles.eventbutton}`} >Click here</button>
            </Link>     
              
          </div>
        
            {/* <div className="col-md-4 mt-3 pe-0">
                <Link
                  href="https://www.shankariasacademy.com/pdf/CUT-OFF-UPSC-Result-2024.pdf"
                  className={`${styles.acheiverrank} ${styles.fonthover, styles.courseknowmore}`}
                  id="upsccourseknowmore"
                  style={{ whiteSpace: "nowrap" }}
                >
                    UPSC PRELIMS - 2024 CUT OFF PREDICTION<span><Image src={NewiconA} alt="New_icon" className="img-fluid mx-2" /></span>
                </Link>
              </div> */}
      </div>
      {/* For Large Device */}
      <div className="container pt-5 d-none d-md-block">
        <div className="row mx-auto">
          <div className={`${styles.coursesimg} col-md-6`}>
            <div className="row">
              <div className="col-md-8">

                <h2
                  className={`${styles.sectiontitle} ${styles.fonthover} pt-5`}
                >
                  UPSC Courses
                </h2>
              </div>
              <div className="col-md-4 pt-5 mt-3 pe-0 text-end">
                <Link
                  href="/about-upsc"
                  className={`${styles.acheiverrank} ${styles.fonthover, styles.courseknowmore}`}
                  id="upsccourseknowmore" onclick="fbq('track', 'ButtonClick');"
                  style={{ whiteSpace: "nowrap" }} 
                >
                  Know More
                </Link>
              </div>
              <ul className="list-unstyled">
                <li className="pb-3">
                  <Link
                    href="/upsc-admission-anna-nagar"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="upsc-ias-ips-admissioncourseshover1"  onclick="fbq('track', 'ButtonClick');"
                  >
                   UPSC - Prelims cum Mains (Foundation Course)
                  </Link>
                </li>


                <li className="pb-3">
                  <Link
                      href="/two-year-upsc-program"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      Sadhana (II Year UPSC Foundation Course)<span><Image src={Newicon} width="27" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li> 

             <li className="pb-3">
                  <Link
                      href="/upsc-interview"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC Civilisation interview 2024<span><Image src={NewiconA} width="32" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>

             <li className="pb-3">
                  <Link
                      href="/ifs-interview"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC IFS interview 2024<span><Image src={NewiconA} width="32" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>

             <li className="pb-3">
                  <Link
                      href="/current-affairs-upsc"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     Chakra Current Affairs Program 2024<span><Image src={NewiconA} width="32" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>

             <li className="pb-3">
                  <Link
                      href="/upsc-prestorming"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    UPSC Prelims Test Series 2025 <span><Image src={NewiconA} width="32" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>

             <li className="pb-3">
                  <Link
                      href="/upsc-optional-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      UPSC Optional Test Series 2025<span><Image src={NewiconA} width="32" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>   


                <li className="pb-3">
                  <Link
                      href="/upsc-mains-test-series"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    UPSC GS Mains Test series 2025
                  </Link>
             </li>

             
             

            {/*  <li className="pb-3">
                  <Link
                      href="/upsc-ifos-optional-test-series"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      UPSC Forestry Optional Test Series 2024
                  </Link>
             </li>    

      
            <li className="pb-3">
                  <Link
                      href="/forestry-admission"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    UPSC Forestry Optional<span></span>
                  </Link>
             </li> */}

           
                <li className="pb-3">
                  <Link
                    href="/upsc-environment-book"
                    className={`${styles.acheiverrank} ${styles.fonthover, styles.courseshover}`}
                    id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                  >
                    UPSC Environment Book Buy Now
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="row"
              style={{ border: "1px solid #D9DFE6", borderLeft: "none" }}
            >
              <div className="col-md-8">
                <h2 className={`${styles.sectiontitle} ps-2 pt-5`}>
                  TNPSC Courses
                </h2>
              </div>
              <div className="col-md-4 pt-5 mt-3 text-end">
                <Link
                  href="/tnpsc-admissions"
                  className={`${styles.acheiverrank} ${styles.fonthover, styles.courseknowmore}`}
                  id="courseshover"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Know More
                </Link>
              </div>
              <ul className="list-unstyled px-4 pb-3">
              <li className="pb-3">
                  <Link
                    href="/tnpsc-admissions/#gspcm"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                    Gr I, II & IIA Prelims Coaching
                  </Link>
                </li>

                <li className="pb-3">
                  <Link
                    href="https://www.shankariasacademy.com/blog/tnpsc-group-iia-mains-mock-test/"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group IIA Mains Mock Test Registration 2025<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="https://www.shankariasacademy.com/blog/tnpsc-group-ii-mains-mock-test/"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group II Mains Mock Test Registration 2025<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    href="/tnpsc-group-i-prelims-test-series"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group I Prelims Test Series 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>

                <li className="pb-3">
                  <Link
                    href="/tnpsc-group-iv-test-batch"
                    className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group IV Prelims Test Series 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
                </li>


                
                

               {/*  <li className="pb-3">
                  <Link
                    href="/tnpsc-group-i-mains-test-series"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group I Mains Test Series 2024
                  </Link>
                </li>

               <li className="pb-3">
                  <Link
                    href="/tnpsc-group-i-mains-crash-course"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-admissionscourseshover1" 
                  >
                   TNPSC Group I Mains Crash Course 2024
                  </Link>
                </li> 


                
                <li className="pb-3">
                  <Link
                    href="/si-test-series"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-group-iv-test-series" 
                  >
                   TNUSRB-SI Test Series 2024
                  </Link>
                </li>*/}

 
              </ul>
              <hr />
              <div className="row">
                <div className="col pt-4 pb-4">
                  <h2 className={`${styles.sectiontitle} ps-2`}>
                    Banking Courses
                  </h2>
                  <ul className="list-unstyled ps-2">

            
                    <li className="pb-3">
                      <a href="https://www.shankarbankingacademy.com/ssc-banking-admission/" target="_blank" rel="noreferrer"
                        className={`${styles.acheiverrank, styles.courseshover2}`}
                        id="bankingcourseshover1" 
                      >
                        BANKING / SSC Admission<span><Image src={NewiconA} width="30" height="30" alt="New_icon" className="img-fluid mx-2" /></span>
                      </a>
                    </li>
                    
                    <li className=" pb-5">
                      <Link
                        href="https://www.shankarbankingacademy.com/ssc-banking-admission/"
                        className={`${styles.acheiverrank, styles.courseshover2}`}
                        id="IBPS-PO-mock-interview-courseshover2" 
                        target="_blank" rel="noreferrer"
                      >
                         SSC CGL CRASH COURSE 2024<span><Image src={Newicon} alt="New_icon" className="img-fluid mx-2" /></span>
                      </Link>
                    </li>
                    

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Mobile Device */}
      <div className="container pt-4 d-block d-md-none">
        <div className="row mx-auto" style={{ border: "1px solid #D9DFE6" }}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <h2 className={`${styles.sectiontitle} pt-3`}>
                      UPSC Courses
                    </h2>
                  </div>
                  <div className="col-4 pt-4">
                    <Link
                      href="/about-upsc"
                      className={`${styles.acheiverrank, styles.courseknowmore}`}
                      id="upsccourseknowmoreformobile"
                      style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                    >
                      Know More
                    </Link>
                  </div>
                </div>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link
                      href="/upsc-admission-anna-nagar"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="upsc-ias-ips-admissioncourseshover2"  onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC - Prelims cum Mains (Foundation Course)
                    </Link>
                  </li>

                 
                <li className="pb-3">
                  <Link href="/two-year-upsc-program" className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    Sadhana (II Year UPSC Foundation Course)
                   </Link>
                 </li>

                 <li className="pb-3">
                  <Link href="/upsc-interview" className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    UPSC Civilisation interview 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                   </Link>
                 </li>

                 
                 <li className="pb-3">
                  <Link href="/ifs-interview" className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    UPSC IFS interview 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                   </Link>
                 </li>

                 <li className="pb-3">
                  <Link href="/current-affairs-upsc" className={`${styles.acheiverrank, styles.courseshover2}`}
                    id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                    Chakra Current Affairs Program 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                   </Link>
                 </li>

                <li className="pb-3">
                  <Link
                      href="/upsc-prestorming"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC Prelims Test Series 2025 <span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
               </li>


              <li className="pb-3">
                  <Link
                      href="/upsc-mains-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC GS Mains Test series 2025<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li>

            

             <li className="pb-3">
                  <Link
                      href="/upsc-optional-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      UPSC Optional Test Series 2025
                  </Link>
             </li>

             <li className="pb-3">
                  <Link
                      href="/upsc-ifos-optional-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      UPSC Forestry Optional Test Series 2024
                  </Link>
             </li>
          

           {/* <li className="pb-3">
                  <Link
                      href="/sia-all-india-mains-mock-test"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     All India Mains Mock Test 2024<span><Image src={NewiconA} alt="New_icon" className="img-fluid mx-2" /></span>
                  </Link>
             </li> 

             <li className="pb-3">
                  <Link
                      href="/forestry-admission"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                     UPSC Forestry Optional
                  </Link>
             </li>*/}

     
            <li className="mb-2">
                <Link
                      href="/upsc-environment-book"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                        id="allindiaprelimshover1" onclick="fbq('track', 'ButtonClick');"
                    >
                      UPSC Environment Book Buy Now
                </Link>
            </li>
                 
          </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-8">
                    <h2 className={`${styles.sectiontitle} pt-3`}>
                      TNPSC Courses
                    </h2>
                  </div>
                  <div className="col-4 pt-4">
                    <Link
                      href="/tnpsc-admissions"
                      className={`${styles.acheiverrank, styles.courseknowmore}`}
                      id="tnpsccourseknowmore"
                      style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                    >
                      Know More
                    </Link>
                  </div>
                </div>

                <ul className="list-unstyled">
                <li className="mb-2">
                    <Link
                      href="/tnpsc-admissions"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                      Gr I, II & IIA Prelims Coaching
                    </Link>
                  </li> 

                  <li className="mb-2">
                    <Link
                      href="https://www.shankariasacademy.com/blog/tnpsc-group-iia-mains-mock-test/"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                        TNPSC Group IIA Mains Mock Test Registration 2025<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="https://www.shankariasacademy.com/blog/tnpsc-group-ii-mains-mock-test/"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                        TNPSC Group II Mains Mock Test Registration 2025<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-i-prelims-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                        TNPSC Group I Prelims Test Series 2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                    </Link>
                  </li>

                  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-iv-test-batch"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                        TNPSC Group IV Prelims Test Series-2024<span><Image src={NewiconA} width="32" height="32" alt="New_icon" className="img-fluid mx-2" /></span>
                    </Link>
                  </li>

                 

                  {/*  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-i-mains-test-series"
                      className={`${styles.acheiverrank, styles.courseshover2}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                     TNPSC Group I Mains Test Series 2024
                    </Link>
                  </li>

                  <li className="mb-2">
                    <Link
                      href="/tnpsc-group-i-mains-crash-course"
                      className={`${styles.acheiverrank, styles.courseshover}`}
                      id="tnpsc-admissionscourseshover1" 
                    >
                     TNPSC Group I Mains Crash Course 2024
                    </Link>
                  </li> 

               

                
                <li className="mb-2">
                  <Link
                    href="/si-test-series"
                    className={`${styles.acheiverrank, styles.courseshover}`}
                    id="tnpsc-group-i-prelims-test-series" 
                  >
                    TNUSRB-SI Test Series 2024
                  </Link>
                </li>*/}
              
                
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <hr />
          </div>
          <div className="col">
            <h2 className={`${styles.sectiontitle}`}>Banking Courses</h2>
            <ul className="list-unstyled">
             
              
              <li>
                <a href="https://www.shankarbankingacademy.com/" target="_blank" rel="noreferrer"
                  className={`${styles.acheiverrank, styles.courseshover2} pt-3`}
                  id="bankingcourseshover2" 
                >
                 BANKING / SSC Admission<span><Image src={Newicon} width="26" height="26"  alt="New_icon" className="img-fluid mx-2" /></span>
                </a>
              </li>

             
              <li>
                <Link
                  href="https://www.shankarbankingacademy.com/ssc-banking-admission/"
                  className={`${styles.acheiverrank, styles.courseshover2}`}
                  id="IBPS-PO-mock-interview-courseshover2" 
                  target="_blank" rel="noreferrer"
                >
                 SSC CGL CRASH COURSE 2024<span><Image src={Newicon} width="26" height="26" alt="New_icon" className="img-fluid mx-2" /></span>
                </Link>
              </li>

              
            </ul>
          </div>
        </div>
      </div>

      {/* Location From Medium Device */}
      <div className="container-fluid" style={{ backgroundColor: "#FFF2F2" }}>
        <div className="container d-none d-md-block pt-5">
          <div className="row mx-auto" style={{ backgroundColor: "#FFF2F2" }}>
            <div className={`${styles.couresesimg1} col-lg-6 col-12 p-5`}>
              <h2 className={`${styles.sectiontitle} mb-0`}>
                Mentoring thousands of
              </h2>
              <h2 className={`${styles.sectiontitle}`}>aspirants across India</h2>
              <Link href="/contact-us">
                <button className={`${styles.normalbutton}`} type="submit">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className="col-lg-6 col-12 pt-5 p-3">
              <div className="row mx-auto">
                <div className="col-md-5 p-3">
                  <Link href="/upsc-admission-anna-nagar" className={`${styles.locationid, styles.courseshover}`} id="upsc-ias-ips-admissioncourseshover3">
                    <Image src={pin} alt="Shankar IAS Academy Anna nagar" title="Anna nagar branch" />
                    &nbsp; &nbsp; Anna Nagar
                  </Link>
                  <hr />
                  <Link href="/adyar" className={`${styles.locationid, styles.courseshover}`} id="adyarcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Adyar" title="Adyar branch" />
                    &nbsp; &nbsp; Adyar
                  </Link>
                  <hr />
                  <Link href="/bengaluru" className={`${styles.locationid, styles.courseshover}`} id="bengalurucourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Bengaluru" title="Bengaluru branch" />
                    &nbsp; &nbsp; Bengaluru
                  </Link>
                  <hr />
                  <Link href="/delhi" className={`${styles.locationid, styles.courseshover}`} id="delhicourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Delhi" title="Delhi branch" />
                    &nbsp; &nbsp; Delhi
                  </Link>
                  <hr />
                  <Link href="/thiruvananthapuram" className={`${styles.locationid, styles.courseshover}`} id="thiruvananthapuramcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Thiruvananthapuram" title="Thiruvananthapuram branch" />
                    &nbsp; &nbsp; Thiruvananthapuram
                  </Link>
                  <hr />
                
                  <Link href="/hyderabad" className={`${styles.locationid, styles.courseshover}`} id="hyderabadcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Hyderabad" title="Hyderabad branch" />
                    &nbsp; &nbsp; Hyderabad
                  </Link>
          
                </div>
                <div className="col-md-5 p-3">
                  <Link href="/trichy" className={`${styles.locationid, styles.courseshover}`} id="trichycourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Trichy" title="Trichy branch" />
                    &nbsp; &nbsp; Trichy
                  </Link>
                  <hr />
                  <Link href="/salem" className={`${styles.locationid, styles.courseshover}`} id="salemcourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Salem" title="Salem branch" />
                    &nbsp; &nbsp; Salem
                  </Link>
                  <hr />
                  <Link href="/madurai" className={`${styles.locationid, styles.courseshover}`} id="maduraicourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Madurai" title="Madurai branch" />
                    &nbsp; &nbsp; Madurai
                  </Link>
                  <hr />
                  <Link href="/coimbatore" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Coimbatore" title="Coimbatore branch" />
                    &nbsp; &nbsp; Coimbatore
                  </Link>

                  <hr />
                  <Link href="/tnpsc-namakkal" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover1">
                    <Image src={pin} alt="Shankar IAS Academy Namakkal" title="namakkal branch" />
                    &nbsp; &nbsp; Namakkal
                  </Link>
                  <hr />


                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Location For Small Device */}
      <div className="container-fluid" style={{ backgroundColor: "#FFF2F2" }}>
        <div className="container d-block d-md-none pt-4">
          <div className="row mx-auto" style={{ backgroundColor: "#FFF2F2" }}>
            <div className={`${styles.couresesimg1} col-md-5 pt-5`}>
              <h2 className={`${styles.sectiontitle} mb-0`}>
                Mentoring thousands of
              </h2>
              <h2 className={`${styles.sectiontitle}`}>aspirants across India</h2>
              <Link href="/contact-us">
                <button className={`${styles.normalbutton}`} type="submit">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className="row pb-4">
              <div className="col-md-5 p-3">
                <Link href="/upsc-admission-anna-nagar" className={`${styles.locationid, styles.courseshover}`} id="upsc-ias-ips-admissioncourseshover4">
                  <Image src={pin} alt="Shankar IAS Academy Anna nagar" title="Anna nagar branch" />
                  &nbsp; &nbsp; Anna Nagar
                </Link>
                <hr />
                <Link href="/adyar" className={`${styles.locationid, styles.courseshover}`} id="adyarcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Adyar" title="Adyar branch" />
                  &nbsp; &nbsp; Adyar
                </Link>
                <hr />
                <Link href="/bengaluru" className={`${styles.locationid, styles.courseshover}`} id="bengalurucourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Bengaluru" title="Bengaluru branch" />
                  &nbsp; &nbsp; Bengaluru
                </Link>
                <hr />
                <Link href="/delhi" className={`${styles.locationid, styles.courseshover}`} id="delhicourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Delhi" title="Delhi branch" />
                  &nbsp; &nbsp; Delhi
                </Link>
                <hr />
                <Link href="/thiruvananthapuram" className={`${styles.locationid, styles.courseshover}`} id="thiruvananthapuramcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Thiruvananthapuram" title="Thiruvananthapuram branch" />
                  &nbsp; &nbsp; Thiruvananthapuram
                </Link>
                <hr />
                <Link href="/trichy" className={`${styles.locationid, styles.courseshover}`} id="trichycourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Trichy" title="Trichy branch" />
                  &nbsp; &nbsp; Trichy
                </Link>
                <hr />
                <Link href="/salem" className={`${styles.locationid, styles.courseshover}`} id="salemcourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Salem" title="Salem branch" />
                  &nbsp; &nbsp; Salem
                </Link>
                <hr />
                <Link href="/madurai" className={`${styles.locationid, styles.courseshover}`} id="maduraicourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Madurai" title="Madurai branch" />
                  &nbsp; &nbsp; Madurai
                </Link>
                <hr />
                <Link href="/coimbatore" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Coimbatore" title="Coimbatore branch" />
                  &nbsp; &nbsp; Coimbatore
                </Link>
                <hr />

                <Link href="/tnpsc-namakkal" className={`${styles.locationid, styles.courseshover}`} id="coimbatorecourseshover2">
                  <Image src={pin} alt="Shankar IAS Academy Coimbatore" title="Coimbatore branch" />
                  &nbsp; &nbsp; Namakkal
                </Link>
                <hr />

                <Link href="/hyderabad" className={`${styles.locationid, styles.courseshover}`} id="hyderabadcourseshover1">
                  <Image src={pin} alt="Shankar IAS Academy Hyderabad" title="Hyderabad branch" />
                  &nbsp; &nbsp; Hyderabad
                </Link>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Online Class For Large Device */}
      <div className="container-fluid" style={{ backgroundColor: "#E2F6FF" }}>
        <div className="container d-none d-md-block mb-0 pt-5">
          <div className="row mx-auto" style={{ backgroundColor: "#E2F6FF" }}>
            <div className="col-xl-5 col-md-12 col-12 p-5">
              <h2 className={`${styles.sectiontitle}`}>
                Looking for online classes?
              </h2>
              <p className={`${styles.acheiverrank} mb-0`}>
                Learn from anywhere, anytime.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                Missed your classes? Worry not.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                We got you covered with our recorded sessions.
              </p>
              <div className="row pt-3">
                <div className="col-xl-5 col-md-4 ps-0">
                  <a href="https://play.google.com/store/apps/details?id=com.vinbytes.shankar_ias" target="_blank" rel="noreferrer">
                    <u className="pe-2">
                      <Image src={googleplay} alt="Google play" title="Google play" />
                    </u>
                  </a>
                </div>
                <div className="col-xl-5 col-md-4 py-2">
                  <a href="https://apps.apple.com/in/app/shankar-ias/id1611747971" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={apple} alt="Apple store" title="Apple store" />
                    </u>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-12">
              <Image
                src={mobileimg}
                alt="Mobile Image banner"
                title="Looking for online classes?"
                className={`image-fluid w-100 h-100 ${styles.lookingForOnlineClass}`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Online Class For Small Device */}
      <div className="container-fluid" style={{ backgroundColor: "#E2F6FF" }}>
        <div className="container d-block d-md-none mb-0 pt-4">
          <div className="row mx-auto" style={{ backgroundColor: "#E2F6FF" }}>
            <div className="col-sm-12 pt-3">
              <h2 className={`${styles.sectiontitle}`}>
                Looking for online classes?
              </h2>
              <p className={`${styles.acheiverrank} mb-0`}>
                Learn from anywhere, anytime.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                Missed your classes? Worry not.
              </p>
              <p className={`${styles.acheiverrank} mb-0`}>
                We got you covered with our recorded sessions.
              </p>
              <div className="row mb-0">
                <div className="col-6">
                  <a href="https://play.google.com/store/apps/details?id=com.vinbytes.shankar_ias" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={googleplay} alt="Google play" title="Google play" />
                    </u>
                  </a>
                </div>
                <div className="col-6 mx-md-2 py-2">
                  <a href="https://apps.apple.com/in/app/shankar-ias/id1611747971" target="_blank" rel="noreferrer">
                    <u>
                      <Image src={apple} alt="Apple store" title="Apple store" />
                    </u>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <Image
                src={mobileimg}
                alt="Mobile Image banner"
                title="Looking for online classes?"
                className="image-fluid w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>


      {/* Why Shankar IAS Academy */}
      <div className="container pt-md-5 pt-4">
        <div className="row">
          <div className="col">
            <h1 className={`${styles.sectiontitle} text-center mb-0`}>
              Why Shankar IAS Academy?
            </h1>
          </div>
        </div>
      </div>

      <div className="container pt-md-5 pt-4">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Link href="/our-achievers"><Image src={icon1} alt="Tnpsc & upsc toppers" title="Shankar IAS Academy Toppers" className="img-fluid" /></Link>              <div className="mx-3 mx-md-4">
                <a href="/our-achievers" className={`${styles.whyshankarcont} text-start mb-0 text-decoration-underline`}>Consistently Producing Toppers Since 2004</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Link href="/our-faculty"><Image src={icon2} alt="Shankar IAS Academy faculty" title="Experienced Faculty Team" className="img-fluid" /></Link>              <div className="mx-3 mx-md-4">
                <a href="/our-faculty" className={`${styles.whyshankarcont} text-start mb-0 text-decoration-underline`}>Experienced Faculty Team (10+ Years)</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Image src={icon3} alt="Tnpsc & upsc achievers" title="Shankar IAS Academy Achievers" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>Regular Guidance From Achievers</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Image src={icon4} alt="Tnpsc & upsc Mentoring" title="Shankar IAS Academy Personalised And Scientific Mentoring" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>Personalised And Scientific Mentoring</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#33527D" }}>
              <Image src={icon5} alt="Tnpsc & upsc Test Series" title="Shankar IAS Academy Test Series" className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>AI Based Test Series (100+ Prelims, 20+ Mains)</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-3 mb-md-4 col-12">
            <div className="p-3 p-md-4 h-100 d-flex align-items-center" style={{ backgroundColor: "#C74A51" }}>
              <Image src={icon6} alt="Tnpsc & upsc Interview Program" title="Shankar IAS Academy Interview Program With Coaching And Mock Interviews." className="img-fluid" />
              <div className="mx-3 mx-md-4">
                <p className={`${styles.whyshankarcont} text-start mb-0`}>3 Months Interview Program With Coaching And Mock Interviews.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col py-3 text-center">
              <Link href={"/why-shankar-ias"} rel="preconnect" className={`${styles.viewbuttonalink}`}>
                <button type="button" className={`${styles.viewbutton}`}>
                  Know More
                </button>
              </Link>
            </div>
          </div>

        </div>
      </div>

      <div className="container pt-3">
        <div className="col text-center">
          <p className={`${styles.civilserviveheading}`}>
            PREFERRED CIVIL SERVICES TRAINING PARTNER OF
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col text-center">
            <Image
              src={preferredcivil}
              alt="Shankar IAS Academy Civil Service"
              title="Shankar IAS Academy CIVIL SERVICES TRAINING PARTNER"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* Our Achievments */}
      <div className="container-fluid pt-md-5 pt-4 px-0">
        <div className="row mx-auto">
          <div className={`${styles.bground} mb-5`}>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <Image src={medal} alt="Our Achievements" title="Best IAS Academy in India" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="container pt-4">
              <div className="row">
                <div className="col">
                  <h2 className={`${styles.sectiontitle} text-center mb-0`}>
                    Our Achievements
                  </h2>
                  <h3
                    className={`${styles.sectionsubtitle} text-center`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    The Best IAS Academy in India
                  </h3>
                </div>
              </div>

              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-md-8">
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    All over India, people aspiring to become IAS, IPS and other elite bureaucratic service officers and seeking to fine tune their preparation, flock to Shankar IAS Academy for Civil Service coaching. With branches in
                  </p>
                  <p className={`${styles.acheiverrank} text-center`}>
                    Chennai, Delhi, Bengaluru, Hyderabad, Thiruvananthapuram, Trichy, Salem, Madurai, Namakkal and Coimbatore, it is the Academy of choice for Civil Service aspirants.
                  </p>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row pt-md-5">
                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>2500+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected for IAS,
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    IPS, IFS and other Elite
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Services from our institute
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>273</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    out of 1016 Selections in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    UPSC CSE 2023 Results
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>21</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Successful Candidates
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    in top 100
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-12 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>2000+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected for
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Group I, II and  IV
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    services from our Institute
                  </p>
                </div>
              </div>

              <div className="row pt-md-4 justify-content-center">
                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>45</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Selections out of 95 Vacancies in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    TNPSC Group I Results 2022
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>450+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Selections out of 1338 Vacancies in
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    TNPSC Group 2(OT) Results 2019
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 pt-5">
                  <p className={`${styles.ourachievementcontent}`}>1000+</p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Candidates selected across
                  </p>
                  <p className={`${styles.acheiverrank} text-center mb-0`}>
                    Various Banks from our Institute
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Acheivers */}
      <div className="container">
        <div className="row">
          <div className="col pt-4 pt-md-5 text-center">
            <h2 className={`${styles.sectiontitle} mb-0`}>Our Achievers</h2>
            <p className={`${styles.sectionsubtitle}`}>
              Rank Holders from our Academy
            </p>
          </div>
        </div>
      </div>
      <Acheiver />

      <div className={`${styles.bg} container-fluid py-5 mt-4 mt-md-5`}>
        <div className="row reveal">
          <div className="container">

            <div className="row">
              <div className="col text-center">
                <h2 className={`${styles.sectiontitle} mb-0`}>Testimonials</h2>
                <p className={`${styles.sectionsubtitle}`}>
                  Hear it from our Achievers
                </p>
              </div>
            </div>

            <div className="ps-0 pe-0">

              <div className="container mb-2 mb-xl-5">
                <div className="row">
                  <div className="col-12 col-lg-6 my-md-3 px-xl-3">
                    {
                      play ?
                        (<iframe src={"https://www.youtube.com/embed/occDdTp0Y3Q?autoplay=1"} className="w-100 h-100  mb-md-3" id="homeyoutube" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderWidth: '0' }}></iframe>)
                        :
                        (<Image src={youtube_thumnail_1} style={{ cursor: 'pointer' }} onClick={() => setPlay(true)} className="img-fluid h-100 w-100  mb-md-3"
                          id="homeyoutube" alt="youtube thumbnail"></Image>)
                    }
                    <div className="d-none d-md-block"><Link
                      href="https://www.iasparliament.com/UPSC-IAS-daily-current-videos"
                      target="_blank"
                      className={`${styles.acheiverrank, styles.courseknowmore} float-end`}
                    >
                      All Current Affairs
                    </Link></div>
                  </div>
                  <div className="col-12 col-lg-6 pt-3 pt-md-0 my-md-3 px-xl-3">
                    {
                      play1 ?
                        (<iframe src={"https://www.youtube.com/embed/K7pvCAPLjrI?autoplay=1"} className="w-100 h-100" id="homeyoutube" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ borderWidth: '0' }}></iframe>)
                        :
                        (<Image src={youtube_thumnail_2} style={{ cursor: 'pointer' }} onClick={() => setPlay1(true)} className="img-fluid h-100 w-100"
                          id="homeyoutube" alt="youtube thumbnail"></Image>)
                    }
                  </div>
                </div>
                <div className="pt-3 d-block d-md-none"><Link
                  href="https://www.iasparliament.com/UPSC-IAS-daily-current-videos"
                  target="_blank"
                  className={`${styles.acheiverrank, styles.courseknowmore}`}
                >
                  All Current Affairs
                </Link>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col pt-3 text-center">
                <Link href={"https://youtube.com/playlist?list=PLolj3WrQmJe5DioIDDGDUHlKgGp5KwiVQ"} target={"_blank"} rel="preconnect" className={`${styles.viewbuttonalink}`}>
                  <button type="button" className={`${styles.viewbutton}`}>
                    View All
                  </button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
