import Navmob from "./Nav mob";
import NewNav from "./NewNav";
const Navbar = (props) => {
  return (
    <>
     <noscript><iframe
    src="https://www.googletagmanager.com/ns.html?id=GTM-5H56PQT"
    height="0"
    width="0"
    style={{ display: 'none', visibility: 'hidden' }}
  ></iframe></noscript>
      <Navmob title="home" />
      <NewNav title="home" />
    </>
  )
}
export default Navbar;
