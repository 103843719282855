import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Link from "next/link";
import Image from "next/image";

import carouselBGimg23 from "../../assets/images/home/Banner/Web-Banner---Click-here.jpg";
import carouselBGimg2 from "../../assets/images/home/Banner/UPSC-CSE-2024-Webbanner.jpg";
import carouselBGimg3 from "../../assets/images/home/Banner/UPSC-Result-2023-Web-Banner.webp";
import carouselBGimg4 from "../../assets/images/home/Banner/Prefit-2025-Web-Banner.jpg";
import carouselBGimg5 from "../../assets/images/home/Banner/UPSC-Result-2023-National Topper-Webpanner.webp";
import carouselBGimg6 from "../../assets/images/home/Moksha/Moksha-2025-Web-Banner-Clickhere.jpg";
import carouselBGimg8 from "../../assets/images/home/Banner/Sadhana-webbanner-2026.jpg";
import carouselBGimg9 from "../../assets/images/home/Banner/TNPSC-New-location-Web-banner-Clickhere.jpg";
import carouselBGimg11 from "../../assets/images/home/Banner/Congralutations-banner.jpg";
import carouselBGimg13 from "../../assets/images/home/Banner/TNPSC-GR-IV-Web-Banner-ClickHere.jpg";

import carouselBGimg16 from "../../assets/images/home/Banner/Web/TNPSC-GR-I-Result-2022_Webbanner.webp";
import carouselBGimg19 from "../../assets/images/home/Banner/prestorming/prestorming-web-banner-Batch-B-V-02.jpg";
import carouselBGimg21 from "../../assets/images/home/Banner/Interview/Interview-Web-banner-Clickhere.jpg";
import carouselBGimg22 from "../../assets/images/home/Banner/TNPSC-GRI-Prelims-Testseries-Web-banner-Clickhere.jpg";

{/* mobile */}

import carouselMBimg23 from "../../assets/images/home/Banner/Mobile-Banner---Click-here.jpg";
import carouselMBimg2 from "../../assets/images/home/Mob_Banner/UPSC-CSE-2024-Mobbanner.jpg";
import carouselMBimg3 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-Moblie-Banner.webp";
import carouselMBimg4 from "../../assets/images/home/Mob_Banner/Prefit-2025-Mobile-Banner.jpg";
import carouselMBimg13 from "../../assets/images/home/Banner/TNPSC-GR-IV-Mobile-Banner-Click-Here.jpg";
import carouselMBimg5 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-National-toppers-Mobilepaner.webp";
import carouselMBimg6 from "../../assets/images/home/Mob_Banner/Foresty-Result-2023-Moblie-Banner.jpg";
import carouselMBimg7 from "../../assets/images/home/Mob_Banner/Sadhana-Mobile-Banner-2026.jpg";
import carouselMBimg8 from "../../assets/images/home/Mob_Banner/Congralutations-banner.jpg";
import carouselMBimg9 from "../../assets/images/home/Mob_Banner/TNPSC-New-location-Mobile-banner-Clickhere.jpg";
import carouselMBimg11 from "../../assets/images/home/Moksha/Moksha-2025-Mob-Banner-Clickhere.jpg";

import carouselMBimg16 from "../../assets/images/upsc/G1-Result/TNPSC-GR-I-Result-2022-Mobilebanner.jpg";
import carouselMBimg19 from "../../assets/images/home/Banner/Prestorming/prestorming-Mob-banner-Batch-b-V-02.jpg";
import carouselMBimg21 from "../../assets/images/home/Banner/Interview/Interview-Mob-banner-Clickhere.jpg";
import carouselMBimg22 from "../../assets/images/home/Banner/TNPSC-GRI-Prelims-Testseries-Mob-banner-Clickhere.jpg";

function slider() {

    const BGimg = [
    
      { quickLoading: false, img: carouselBGimg23, alt: "carousel", link: "https://forms.gle/pAYjFCPmSyE35PK36" },
      { quickLoading: false, img: carouselBGimg21, alt: "carousel", link: "/upsc-interview" },
      { quickLoading: false, img: carouselBGimg8, alt: "carousel", link: "/two-year-upsc-program" },
      { quickLoading: false, img: carouselBGimg4, alt: "carousel", link: "/upsc-prefit-test" },
      { quickLoading: false, img: carouselBGimg19, alt: "carousel", link: "/upsc-prestorming-prelims-test-series" },
      { quickLoading: false, img: carouselBGimg22, alt: "carousel", link: "/tnpsc-group-i-prelims-test-series" },
     
      
      { quickLoading: false, img: carouselBGimg13, alt: "carousel", link: "/tnpsc-group-iv-test-batch" },
      { quickLoading: false, img: carouselBGimg6, alt: "carousel", link: "/upsc-mains-test-series" },
      { quickLoading: false, img: carouselBGimg11, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      { quickLoading: false, img: carouselBGimg16, alt: "carousel", link: "/tnpsc-admissions" },
      { quickLoading: false, img: carouselBGimg9, alt: "carousel", link: "/tnpsc-admissions" },
     

      ]

      const MBimg = [
       
        { quickLoading: false, img: carouselMBimg23, alt: "carousel", link: "https://forms.gle/pAYjFCPmSyE35PK36" },
        { quickLoading: false, img: carouselMBimg21, alt: "carousel", link: "/upsc-interview" },
        { quickLoading: false, img: carouselMBimg22, alt: "carousel", link: "/tnpsc-group-i-prelims-test-series" },
        { quickLoading: false, img: carouselMBimg13, alt: "carousel", link: "/tnpsc-group-iv-test-batch" },
        { quickLoading: false, img: carouselMBimg4, alt: "carousel", link: "/upsc-prefit-test" },
        { quickLoading: false, img: carouselMBimg11, alt: "carousel", link: "/upsc-mains-test-series" },
        { quickLoading: false, img: carouselMBimg19, alt: "carousel", link: "/upsc-prestorming-prelims-test-series" },
        { quickLoading: false, img: carouselMBimg7, alt: "carousel", link: "/two-year-upsc-program" },
        { quickLoading: false, img: carouselMBimg8, alt: "carousel", link: "/upsc-admission-anna-nagar" },
      
       
        { quickLoading: false, img: carouselMBimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg6, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg16, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg9, alt: "carousel", link: "/tnpsc-admissions" },

      ]
  
  return (
    <>
        {/* Carousel large devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-md-block">
            <Carousel>
              {BGimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid w-100 h-100"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

      {/* Carousel Mobile devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-block d-md-none">
            <Carousel>
              {MBimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

    </>
  )
}

export default slider